<!--
 * @Description: 转介绍
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-07-27 11:02:53
 * @LastEditors: Seven
 * @LastEditTime: 2022-04-18 09:50:52
-->
<template>
  <div class="introduce">
    <div ref="contentTop">
      <div class="tool">
        <div class="customer-tool">
          <span class="tab-bar clearfix">
            <span
              @click="handleClick(item.path)"
              v-for="(item, index) in tabList"
              :class="{ active: item.path === activeName }"
              :key="index"
              >{{ item.name }}</span
            >
          </span>
          <div style="line-height: 40px;">
            <!-- 导出 -->
            <Export 
              v-if="permUtil.WbCusotmer.invalidExport()" 
              :pageParam="pageParam" 
              :searchParam="searchParam" 
              :total="total" 
              code="introduce" />
          </div>
        </div>
      </div>
      <div style="width: 94%; margin: 0 auto">
        <Search
          v-if="searchParamList.length > 0"
          :searchAllParam="searchAllParam"
          @search="search"
          @reset="reset"
          @searchMore="searchMore"
          @searchList="searchList"
          :searchParamList="searchParamList"
          :searchParamFromApi="searchParamFromApi"
          code="introduce"
        />
      </div>
      <div class="line"></div>
    </div>
    <el-row style="width: 94%; margin: 0 auto">
      <el-col>
        <el-table
          highlight-current-row
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          :height="tableHeight"
          size="mini"
          v-loading="listLoading"
          element-loading-text="拼命加载中"
          :row-style="{ height: '0px' }"
          :cell-style="{ padding: '0px' }"
          :header-cell-style="{ background: '#e5f2ff' }"
          @selection-change="selectionRow"
          @sort-change="sortChange"
        >
          <el-table-column :show-overflow-tooltip="true"
            label="学员姓名"
            prop="name"
            min-width="120"
            
          >
            <template slot-scope="scope">
              <el-button type="text" @click="openDetail(scope.row)">
                {{ scope.row.name }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="预约校区"
            prop="appointmentSchoolName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="招生老师"
            prop="recruitTeacherName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="备案老师"
            prop="recommendUserName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="备案日期"
            prop="recommendTime"
            min-width="140"
            
          >
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="截止日期"
            prop="expireTime"
            min-width="160"
            
          >
          </el-table-column>
          <!-- <el-table-column :show-overflow-tooltip="true"
            label="产品线"
            prop="tyProductTypeName"
            min-width="150"
            
          ></el-table-column> -->
          <el-table-column :show-overflow-tooltip="true"
            label="转介绍类型"
            prop="transIntroduceTypeName"
            min-width="150"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="公开课备案类型"
            prop="courseTypeName"
            min-width="150"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="公开课名称"
            prop="courseName"
            min-width="140"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="讲师"
            prop="teacherName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="讲课日期"
            prop="teachingTime"
            min-width="160"
            
          >
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="备案老师类型"
            prop="recommendTeacherTypeName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="备注"
            prop="memo"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="已购商品"
            prop="productName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="赛道"
            prop="crmTrackTypeName"
            min-width="120"
          >
          </el-table-column>
          <el-table-column
            label="是否外派"
            prop="lecturerAssigned"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.lecturerAssigned == true">是</span>
              <span v-else-if="scope.row.lecturerAssigned == null"> </span>
              <span v-else>否</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="120"  fixed="right">
            <template slot-scope="scope">
              <!-- 暂时还没控制报名按钮的显示隐藏 -->
              <el-button v-if="scope.row.validStatus" type="text" @click="goSignUpPage(scope.row)"
                >报名</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="page_test_bottom">
          <el-pagination
            background
            @size-change="sizeChange"
            @current-change="currentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="pageParam.pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageParam.pageSize"
            :total="total"
          />
        </div>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <Detail ref="drawer" :method="getRecomendCustomerList" />
  </div>
</template>
<script>
import Search from "components/search";
import Detail from "./components/detail";
import Export from "./components/export";
import { 
  getUserCustomQuery, 
  getDeptList, 
  getUserList, 
  getTrackTypeList, 
  getRecommentUserList 
} from "api/public";
import { 
  doInvalidInspect, 
  getRecomendCustomerList,
  getTransactionInfo
} from "api/customer";

export default {
  name: "introduce",
  components: {
    Search,
    Detail,
    Export,
  },
  data() {
    return {
      activeName: "-1",
      tabList: [
        { name: "所有", path: "-1" },
        { name: "普通转介绍", path: "0" },
        { name: "公开课", path: "1" },
      ],
      searchAllParam: [
        { name: "name", label: "学员姓名", type: "input", value: "" },
        { name: "phone", label: "手机号码", type: "input", value: "" },
        {
          name: "appointmentCrmDeptId",
          label: "预约校区",
          type: "tree",
          value: "",
          option: [],
        },
        {
          name: "courseTypeIdList",
          label: "公开课备案类型",
          type: "dictMult",
          value: "",
          option: [],
          text: "name",
          id: "id",
          code: "SM_COURSE_TYPE"
        },
        {
          name: "recommendTeacherType",
          label: "备案老师类型",
          type: "dict",
          value: "",
          option: [],
          text: "name",
          id: "id",
          code: "RECOMMEND_TEACHER_TYPE"
        },
        {
          name: "recruitCrmUserId",
          label: "招生老师",
          type: "select",
          value: "",
          option: [],
          text: "name",
          id: "id"
        },
        {
          name: "recommendNcUserId",
          label: "备案老师",
          type: "select",
          value: null,
          option: [],
          text: "nickName",
          id: "ncId"
        },
        {
          name: "recommendTime",
          label: "备案日期",
          type: "date",
          value: [],
          ref: "create",
          show: false,
        },
        {
          name: "expireTime",
          label: "截止日期",
          type: "date",
          value: [],
          ref: "invalid",
          show: false,
        },
        { name: "crmCustomerId", label: "客户编号", type: "input", value: "" },
        {
          name: 'lecturerAssigned',
          label: '是否外派',
          type: "select",
          value: "",
          option: [{
            name: "是",
            id: true
          }, {
            name: "否",
            id: 2
          }],
          text: "name",
          id: "id"
        }
      ],
      searchParamList: [],
      defaultParamList: ["name", "phone", "createTime"],
      searchParamFromApi: [],
      tableData: [],
      listLoading: false,
      currentPage: 1,
      total: 0,
      pageParam: {
        pageNum: 1,
        pageSize: 20,
        sortItems: [
          {
            asc: false,
            column: "",
          },
        ],
      },
      inspectShow: false,
      form: {
        bizStatus: "",
        type: "",
        result: "",
      },
      checkList: [
        { label: "有效商机", value: 0 },
        { label: "无效商机", value: 1 },
      ],
      searchParam: {},
      topHeight: 0,
    };
  },
  computed: {
    tableHeight() {
      return window.outerHeight - this.topHeight - 256 + 'px'
    }
  },
  mounted() {
    this.getSearchList();
    this.getRecomendCustomerList();
    this.searchAllParam.map((item) => {
      if (item.name === "appointmentCrmDeptId") {
        getDeptList().then((data) => item.option = data.result);
      } else if (item.name === "recruitCrmUserId") {
        getUserList().then((data) => item.option = data.result);
      } else if(item.name === "inspectUserId") {
        getTrackTypeList(0).then(data => item.option = data.result)
      } else if(item.name === "recommendNcUserId") {
        getRecommentUserList({limit: -1, page: 1}).then(data => item.option = data.data.result)}
    });
  },
  methods: {
    // tab切换
    handleClick(index) {
      this.activeName = index;
      this.$router.push({ query: { type: this.activeName } });
      this.getRecomendCustomerList()
    },
    // 更新自定义查询
    searchList() {
      this.getSearchList();
    },
    // 更多查询条件
    searchMore(value) {
      this.searchParamList = value
        ? this.searchAllParam
        : this.defaultParamList;
      this.$nextTick(() => {
        this.topHeight = this.$refs.contentTop.offsetHeight + 8
      })
    },
    // 获取自定义查询的默认查询字段
    async getSearchList() {
      const { code, result } = await getUserCustomQuery("introduce");
      if (code === 0) {
        this.defaultParamList = ["name", "phone", "createTime"]
        if (result && result.fieldData && JSON.parse(result.fieldData).length > 0) {
          this.searchParamFromApi = JSON.parse(result.fieldData);
          
          this.defaultParamList = []
          this.searchParamFromApi.map(() => {
            this.defaultParamList.push("")
          })
          let index
          this.searchAllParam.filter((item) => {
            index = this.searchParamFromApi.findIndex(i => i === item.name)
            this.defaultParamList[index] = item
          });
        } else {
          this.defaultParamList = this.searchAllParam.filter((item) =>
            this.defaultParamList.includes(item.name)
          );
        }
        this.searchParamList = this.defaultParamList;
        this.$nextTick(() => {
          this.topHeight = this.$refs.contentTop.offsetHeight + 8
        })
      }
    },
    // 打开详情
    openDetail(row) {
      row.customerId = row.crmCustomerId
      this.$refs.drawer.init(row)
    },
    // 获取列表数据
    async getRecomendCustomerList() {
      if(this.activeName != -1) {
        this.searchParam.recommendType = this.activeName;
      } else {
        delete this.searchParam.recommendType
      }
      if(this.searchParam.expireBeginTime) {
        this.searchParam.expireBeginTime = this.searchParam.expireBeginTime + ' 00:00:00';
        this.searchParam.expireEndTime = this.searchParam.expireEndTime + ' 23:59:59';
      } else {
        delete this.searchParam.expireBeginTime
        delete this.searchParam.expireEndTime
      }
      this.listLoading = true;
      const { code, result, msg } = await getRecomendCustomerList({...this.pageParam, ...this.searchParam});
      if (code === 0) {
        this.tableData = result.list;
        this.total = result.total;
      } else {
        this.$message.error(msg)
      }
      this.listLoading = false;
    },
    // 多选事件--
    selectionRow(val) {
      this.currList = val;
    },

    // 改变分页条数
    sizeChange(val) {
      this.pageParam.pageSize = val;
      this.getRecomendCustomerList();
    },

    // 改变分页
    currentChange(val) {
      this.pageParam.pageNum = val;
      this.getRecomendCustomerList();
    },
    // 排序
    sortChange(val) {
      this.pageParam.sortItems[0].column = val.prop;
      this.getRecomendCustomerList();
    },
    // 报名
    goSignUpPage(row) {
      // 有授权报名权限且该转介绍单/小型公开课备案单在有效期内的才显示“报名”功能
      let params = {
        customerId: row.crmCustomerId,
        sourceId: row.id,
        registSource: 1
      }
      getTransactionInfo(params).then(data => {
        if(data.code === 0) {
          this.$router.push(`/afterSale/signup?customerId=${row.crmCustomerId}&registSource=1&sourceId=${row.id}`);
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    // 查询
    search() {
      this.searchParam = {};
      this.searchAllParam.map((item) => {
        if (( Array.isArray(item.value)  && item.value.length) || item.value) {
          if (item.type === "date") {
            if (item.name === "recommendTime") {
              this.searchParam.recommendBeginTime = item.value[0];
              this.searchParam.recommendEndTime = item.value[1];
            } else if (item.name === "expireTime") {
              this.searchParam.expireBeginTime = item.value[0];
              this.searchParam.expireEndTime = item.value[1];
            }
          }  if (item.name === "lecturerAssigned") {
            if (item.value == 2) {
              this.searchParam.lecturerAssigned = false
            } else{
              this.searchParam.lecturerAssigned = item.value;
            }
            console.log('item.name', item.name);
          } else {
            this.searchParam[item.name] = item.value;
          }
        }
      });
      this.getRecomendCustomerList();
    },
    // 重置
    reset() {
      this.searchAllParam.map((item) => {
        if (!!item.value || item.value === 0) {
          if(item.name === "recommendTime" ||
          item.name === "expireTime") {
            item.value = []
            this.searchParam.recommendBeginTime = "";
            this.searchParam.recommendEndTime = "";
            this.searchParam.expireBeginTime = "";
            this.searchParam.expireEndTime = "";
          } else {
            item.value = ""; 
          }
        }
      });
      this.pageParam = {
        pageNum: 1,
        pageSize: 10,
        sortItems: [
          {
            asc: false,
            column: "",
          },
        ],
      };
      this.search();
    },
    // 保存编辑
    async editSave() {
      console.log('this.form', this.form);
      const { code, msg } = await doInvalidInspect(this.form);
      if (code === 0) {
        this.$message.success(msg);
        this.getRecomendCustomerList();
        this.inspectShow = false;
      } else {
        this.$message.error(msg);
      }
    },

    // 取消编辑
    cancel() {
      (this.form = {
        bizStatus: null,
        type: "",
        result: "",
      }),
        (this.inspectShow = false);
        this.$refs.ChilCheckbox.setStyle()
    },
  },
};
</script>
<style lang="scss" scoped>
.introduce {
  // padding-bottom: 40px;
  .tool {
    background: #f5f7f9;
  }

  .customer-tool {
    width: 94%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .tab-bar {
    overflow: hidden;

    .active {
      color: #409eff;
    }

    > span {
      float: left;
      margin-right: 12px;
      line-height: 40px;
      text-align: center;
      text-decoration: none;
      color: #000;
      cursor: pointer;
      font-size: 14px;
    }
  }
  .search-btn {
    position: absolute;
  }

  .tool-btn {
    position: absolute;
    right: 20px;
    top: 0;
  }
}

.inspect-modal {
  width: 288px;
  margin: 0 auto;

  // .el-col-24 {
  //   margin-top: 18px;
  // }
}
</style>